import LUMPSUM_DISPENSARY_AREA_MAP from '../../../../shared/ramq/domainValues/lumpsumDispensaryAreas';

const generalCareLumpsum = {
  label: 'HOSPIT',
  value: LUMPSUM_DISPENSARY_AREA_MAP.generalCare
};
const externalClinicLumpsum = {
  label: 'EXTERNE',
  value: LUMPSUM_DISPENSARY_AREA_MAP.externalClinic
};
const remoteLumpsum = {
  label: 'À Distance',
  value: LUMPSUM_DISPENSARY_AREA_MAP.remote,
  aliases: [LUMPSUM_DISPENSARY_AREA_MAP.remote, LUMPSUM_DISPENSARY_AREA_MAP.remoteOverride]
};

const dispensaryAreaMap: any = [externalClinicLumpsum, generalCareLumpsum, remoteLumpsum];

export default dispensaryAreaMap;
