import React, { useEffect } from 'react';
import ActivityType from '../../../../../../shared/core/activity/domain/ActivityType';
import FormElement from '../../../../../components/Form/FormElement/FormElement';
import SingleSelectCheckboxGroup from 'app/components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import { PlaceAutocompleteField, SingleLineField } from 'app/components/Form/Fields';
import LUMPSUM_DISPENSARY_AREA_MAP from 'shared/ramq/domainValues/lumpsumDispensaryAreas';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import PERIOD_FORM_NAME from 'app/containers/PeriodsBilling/constants';
import { UnknownAction } from '@reduxjs/toolkit';

interface Props {
  activityType: ActivityType;
  activityArea: string;
  fieldName: string;
  originLocationOfRemoteConsultation?: {
    place?: {
      number: string;
      name: string;
    } | null;
    type: 'geographical' | 'physical' | null;
    postalCode?: null | string;
  };
  classes: {
    unitContainer: string;
  };
}

export const RemoteConsultation: React.FunctionComponent<Props> = ({
  activityType,
  activityArea,
  fieldName,
  originLocationOfRemoteConsultation,
  classes
}: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (activityType === ActivityType.LUMP_SUM && activityArea !== LUMPSUM_DISPENSARY_AREA_MAP.remote) {
      dispatch(change(PERIOD_FORM_NAME, `${fieldName}.originLocationOfRemoteConsultation`, null) as UnknownAction);
    } else if (originLocationOfRemoteConsultation?.type === 'physical') {
      dispatch(
        change(PERIOD_FORM_NAME, `${fieldName}.originLocationOfRemoteConsultation.postalCode`, null) as UnknownAction
      );
    } else if (originLocationOfRemoteConsultation?.type === 'geographical') {
      dispatch(
        change(PERIOD_FORM_NAME, `${fieldName}.originLocationOfRemoteConsultation.place`, null) as UnknownAction
      );
    }
  }, [dispatch, fieldName, originLocationOfRemoteConsultation?.type, activityType, activityArea]);

  if (activityType !== ActivityType.LUMP_SUM || activityArea !== LUMPSUM_DISPENSARY_AREA_MAP.remote) return null;

  return (
    <div className={classnames(classes.unitContainer)}>
      <FormElement
        name={`${fieldName}.originLocationOfRemoteConsultation.type`}
        component={SingleSelectCheckboxGroup}
        list={[
          { label: 'Lieu RAMQ', value: 'physical' },
          { label: 'Lieu géographique', value: 'geographical' }
        ]}
      />
      {originLocationOfRemoteConsultation?.type === 'physical' && (
        <FormElement
          name={`${fieldName}.originLocationOfRemoteConsultation.place`}
          component={PlaceAutocompleteField}
          label="Lieu"
        />
      )}
      {originLocationOfRemoteConsultation?.type === 'geographical' && (
        <FormElement
          name={`${fieldName}.originLocationOfRemoteConsultation.postalCode`}
          component={SingleLineField}
          label="Code postal"
        />
      )}
    </div>
  );
};
