import { createSelector } from '@reduxjs/toolkit';
import PERIOD_FORM_NAME from './constants';

const selectReduxPeriodForm = (state) => state.form[PERIOD_FORM_NAME]?.values ?? {};

export const selectDays = () => createSelector(selectReduxPeriodForm, (reduxFormState) => reduxFormState.days ?? []);

export const selectDayByFieldIndex = (fieldIndex) =>
  createSelector(selectReduxPeriodForm, (reduxFormState) => {
    const { days } = reduxFormState;
    return days[fieldIndex];
  });

export const selectDaysFromDate = (fieldIndex) =>
  createSelector(selectReduxPeriodForm, (reduxFormState) => {
    const { days } = reduxFormState;
    const date = days[fieldIndex]?.date;
    return days.map((day, index) => ({ ...day, fieldIndex: index })).filter((day) => day.date === date);
  });

export const selectBillingType = () =>
  createSelector(selectReduxPeriodForm, (reduxFormState) => reduxFormState.billingType);

export const selectActType = () => createSelector(selectReduxPeriodForm, (reduxFormState) => reduxFormState.type);

export const selectFormType = () =>
  createSelector(selectReduxPeriodForm, (reduxFormState) => reduxFormState.type ?? '');
