import moment from 'moment-timezone';
import { getRamqNumberOfHoursWorkedFromTimestamps } from '../../../ramq/ramqCommonUtils';
import { timeSlotLimits } from '../../timeSlot/timeSlotUtils';
import { addFullRatePrefix, addHalfRatePrefix } from '../codePrefixor/ratePrefixors';

const pmFullTimeLimit = { start: 17, end: 19 };

export const lumpSumIsBilledByAct = (lumpSum) => (lumpSum.place ? lumpSum.place.billByAct : false);

export const makeHalfRateDetailIfNotBilledByAct = (lumpSum, hoursWorked) => {
  if (lumpSumIsBilledByAct(lumpSum)) {
    return makeFullRateDetail(lumpSum, hoursWorked);
  }
  return makeHalfRateDetail(lumpSum, hoursWorked);
};

export const makeFullRateDetail = (lumpSum, hoursWorked) => makeDetail(lumpSum, hoursWorked, addFullRatePrefix);
export const makeHalfRateDetail = (lumpSum, hoursWorked) => makeDetail(lumpSum, hoursWorked, addHalfRatePrefix);

export const makeDetail = (lumpSum, hoursWorked, prefixor) => ({
  code: lumpSum.code ? prefixor(lumpSum) : lumpSum.code,
  hoursWorked,
  sector: lumpSum.activityArea || '03'
});

export const pmHoursWorkedInHalfRate = (timeSlotStartTime, timeSlotEndTime) => {
  const rateStartLimit = moment(timeSlotStartTime)
    .set({
      hour: timeSlotLimits.pm.start,
      minute: 0,
      second: 0
    })
    .valueOf();
  const rateEndLimit = moment(timeSlotEndTime)
    .set({
      hour: pmFullTimeLimit.start,
      minute: 0,
      second: 0
    })
    .valueOf();

  const beginningOfWorkInHalfRate = Math.max(timeSlotStartTime, rateStartLimit);
  const endOfWorkInHalfRate = Math.min(timeSlotEndTime, rateEndLimit);

  return endOfWorkInHalfRate > beginningOfWorkInHalfRate
    ? getRamqNumberOfHoursWorkedFromTimestamps(beginningOfWorkInHalfRate, endOfWorkInHalfRate)
    : '0000';
};

export const pmHoursWorkedInFullRate = (timeSlotStartTime, timeSlotEndTime) => {
  const rateStartLimit = moment(timeSlotStartTime)
    .set({
      hour: pmFullTimeLimit.start,
      minute: 0,
      second: 0
    })
    .valueOf();
  const rateEndLimit = moment(timeSlotEndTime)
    .set({
      hour: pmFullTimeLimit.end,
      minute: 0,
      second: 0
    })
    .valueOf();

  const beginningOfWorkInFullRate = Math.max(timeSlotStartTime, rateStartLimit);
  const endOfWorkInFullRate = Math.min(timeSlotEndTime, rateEndLimit);

  return endOfWorkInFullRate > beginningOfWorkInFullRate
    ? getRamqNumberOfHoursWorkedFromTimestamps(beginningOfWorkInFullRate, endOfWorkInFullRate)
    : '0000';
};

export const codeIsCovidRelated = (code) =>
  [
    '290322',
    '290322-N',
    '290323',
    '290323-N',
    '290333',
    '290329',
    '290331',
    '290330',
    '290332',
    '290334',
    '290338',
    '290339',
    '290336',
    '290337'
  ].includes(code);

export const HALF_DAY_COVID_INFECTED_NOT_REASSIGNED = ['290330', '290332', '290334', '291363', '291364', '291365'];
export const codeIsAnHalfDayDuringCovid = (code) => HALF_DAY_COVID_INFECTED_NOT_REASSIGNED.includes(code);

export const codeIsAReunionsOrReallocationDuringDay = (code) => ['290338', '290339'].includes(code);

export const codeIsRelatedToCovid = (code) =>
  ['290322', '290323', '290333', '290329', '290331', '290336', '290337'].includes(code);

export const codeIsRelatedToChronicLungDiseaseRehabilitation = (code) =>
  ['004030', '004037', '004038', '021059'].includes(code);

export const codeIsIncreasableDuringNight = (code) => ['290322-N', '290323-N'].includes(code);

export const EVAQ_MANAGEMENT_CODES = ['078125', '078126'];
export const EVAQ_CODES = ['078127'];
export const EVAQ_OVERTIME_CODES = ['078128'];
export const isEvaqManagementCode = (code) => EVAQ_MANAGEMENT_CODES.includes(code);
export const isEvaqCode = (code) => EVAQ_CODES.includes(code);
export const isEvaqOvertimeCode = (code) => EVAQ_OVERTIME_CODES.includes(code);

export const SSS_CODES = ['093137', '093138', '094137', '094138'];
export const isSSSCode = (code) => SSS_CODES.includes(code);
export const isSSSPresidentCode = (code) => ['093136', '094136'].includes(code);

export const DIGITAL_HEALTH_COMITEES_CODES = ['330420', '331420'];
export const isDigitalHealthComiteeCode = (code) => DIGITAL_HEALTH_COMITEES_CODES.includes(code);

// Services
export const isClinicalServicesCode = (code) =>
  ['002030', '002064', '026030', '023053', '023065', '023066', '024030', '002032', '002055', '002060'].includes(code);
export const isLaboratoryServicesCode = (code) => ['002053'].includes(code);

export const isMedicoAdministrativeActivitiesCode = (code) => ['085019', '085020', '085021'].includes(code);
