import get from 'lodash/get';
import { AssociatedField } from '../../requiredDetails/requiredDetail.type';
import Act, { ActCode } from '../../../../shared/domain/activity/act/model/Act';
import surgeryRoom from '../../requiredDetails/SurgeryRoom/surgeryRoom';
import { getFeatureConfig } from '../../../../userCustomization/featureToggle';
import { DISPLAY_SURGERY_ROOM_REQUIRED_DETAIL } from '../../../../userCustomization/featureNames';
import typeOfAnesthesia from '../../requiredDetails/TypeOfAnesthesia/typeOfAnesthesia';
import { SURGERY_ROOM } from '../../../../shared/ramq/requiredDetails/surgeryRoom';
import ActPerformedAloneWithAmountLowerThanMaximumAmountPredicate from './ActPerformedAloneWithAmountLowerThanMaximumAmountPredicate';
import { InterventionSide } from 'shared/ramq/requiredDetails/interventionSide';
import pinRemovalTypeOfAnesthesia from 'app/codeSpecificLogic/requiredDetails/TypeOfAnesthesia/pinRemovalTypeOfAnesthesia';
import pinRemovalSurgeryRoom from 'app/codeSpecificLogic/requiredDetails/SurgeryRoom/pinRemovalSurgeryRoom';

export const PIN_REMOVAL_CODES = ['2301', '2304', '2306', '2309', '2312', '2317', '2318', '2793', '2974', '2998'];

const pinRemovalRequiredDetailsAppender = (code: ActCode, act: Act): AssociatedField[][] => {
  if (!getFeatureConfig(DISPLAY_SURGERY_ROOM_REQUIRED_DETAIL, false)) {
    return [];
  }

  if (code.isSupplement) {
    return [];
  }

  if (!PIN_REMOVAL_CODES.includes(code.code)) {
    return [];
  }

  const requiredDetails: AssociatedField[][] = [];

  if (get(code, SURGERY_ROOM, false)) {
    requiredDetails.push(pinRemovalTypeOfAnesthesia.associatedFields);
  }

  requiredDetails.push(pinRemovalSurgeryRoom.associatedFields);

  return requiredDetails;
};

export default pinRemovalRequiredDetailsAppender;
