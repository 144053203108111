import { flatten } from 'lodash';
import addBiopsyFieldSelectIfNeeded from './rules/biopsy';
import addRole1StartTimeFieldForIntensiveCareUnitSupportIfNeeded from './rules/intensiveCareUnitSupport';
import addMeasurementElementsFieldIfNeeded from './rules/measurementElements';
import addPreSurgeryFieldSelectIfNeeded from './rules/preSurgeryIntervention';
import addRoleSelectFieldIfNeeded from './rules/roles';
import ActCodeRequiredDetailsFactory from '../requiredDetails/ActCodeRequiredDetails/ActCodeRequiredDetailsFactory';
import addInterventionSideSelectionIfNeeded from './rules/interventionSideSelection';
import addContextElementsIfNeeded from './rules/contextElements';
import addPlaceReferenceForMultiPlaceGuard from './rules/multiPlaceGuard/multiPlaceGuard';
import addRule30RequiredDetailsAppender from './rules/rule30RequiredDetailsAppender';
import add7000XCodesBilledOnlyWith28CodesRuleAppender from './rules/add7000XCodesBilledOnlyWithPg28CodesRule/add7000XCodesBilledOnlyWithPg28CodesRule';
import addSurgeryNatureTypeIfNeeded from './rules/surgeryNatureType';
import addTelemedicineRequiredDetailIfNeeded from './rules/addTelemedicineRequiredDetailIfNeeded';
import askEventDateForCNESSTCodes from './rules/askEventDateForCNESSTCodes';
import addStartTimeForExtremityDislocationIfNeeded from './rules/extremityDislocation';
import addPinRemovalRequiredDetailsAppender from './rules/pinRemovalRequiredDetailsAppender';
export const getUIOptimizations = (code, act) => {
  const uiOptimizations = ActCodeRequiredDetailsFactory.create(code, act).map(
    (requiredDetail) => requiredDetail.associatedUIOptimizations || []
  );
  return flatten(uiOptimizations);
};

export const getAdditionalFieldsRequiredForCode = (code, codeList, act) => {
  let additionalFields = getAdditionalFieldsFromRequiredDetails(code, act);

  additionalFields = additionalFields.concat(
    addRoleSelectFieldIfNeeded(code, act),
    addPreSurgeryFieldSelectIfNeeded(code, codeList, act),
    addBiopsyFieldSelectIfNeeded(code, codeList),
    addMeasurementElementsFieldIfNeeded(code, act),
    addRole1StartTimeFieldForIntensiveCareUnitSupportIfNeeded(code, codeList, act),
    addInterventionSideSelectionIfNeeded(code),
    addPlaceReferenceForMultiPlaceGuard(code),
    addContextElementsIfNeeded(code),
    add7000XCodesBilledOnlyWith28CodesRuleAppender(act, code),
    addRule30RequiredDetailsAppender(code, act),
    addSurgeryNatureTypeIfNeeded(code, act),
    addTelemedicineRequiredDetailIfNeeded(code),
    askEventDateForCNESSTCodes(code),
    addStartTimeForExtremityDislocationIfNeeded(code, codeList, act),
    addPinRemovalRequiredDetailsAppender(code, act)
  );

  return flatten(additionalFields).filter(fieldShouldBeShown(code, codeList));
};

const getAdditionalFieldsFromRequiredDetails = (code, act) =>
  ActCodeRequiredDetailsFactory.create(code, act).map((requiredDetail) => requiredDetail.associatedFields || []);

const fieldShouldBeShown = (code, codeList) => (field) =>
  !field.shouldFieldBeHidden || !field.shouldFieldBeHidden(code, codeList);
