import { current } from '@reduxjs/toolkit';
import extremityDislocationStartTime from 'app/codeSpecificLogic/requiredDetails/ExtremityDislocation/extremityDislocationStartTime';
import { selectSpecialty } from 'app/containers/User/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import SpecialtyName from 'shared/core/doctor/domain/SpecialtyName';

const extremityDislocationCodes = ['2546', '2549'];

const addStartTimeForExtremityDislocationIfNeeded = (currentCode, codeList, act) =>
  shouldShowStartTimeForExtremityDislocation(currentCode, codeList, act)
    ? extremityDislocationStartTime.associatedFields
    : [];

const shouldShowStartTimeForExtremityDislocation = (currentCode, codeList, act) => {
  const userSpecialty = selectSpecialty()(getStore().getState());

  if (userSpecialty !== SpecialtyName.ORTHOPEDIC_SURGEON) return false;

  return (
    act.codes.some((code) => extremityDislocationCodes.includes(code.code)) &&
    !extremityDislocationCodes.includes(currentCode.code) &&
    currentCode.description.startsWith('Musculo-squelettique')
  );
};

export default addStartTimeForExtremityDislocationIfNeeded;
