import ROLE_1_FORCED_START_TIME from '../../../../shared/ramq/requiredDetails/intensiveCareUnitSupport';
import { formatUnixToTimeWithHourPrefixValue } from '../../../../shared/utils/dateTime/dateTimeUtils';
import TimeSyncedWithActStartTime from '../../../components/Form/ActSpecificField/TimeSyncedWithActStartTime/TimeSyncedWithActStartTime';
import DIFFERENT_SESSION_START_TIME from '../../../../shared/ramq/requiredDetails/extremityDislocation';

const summaryRender = (code) =>
  code[DIFFERENT_SESSION_START_TIME]
    ? [
        {
          name: 'heure début',
          value: formatUnixToTimeWithHourPrefixValue(code[DIFFERENT_SESSION_START_TIME])
        }
      ]
    : [];

const associatedFields = [
  {
    label: 'Heure début',
    getName: (index) => `codes[${index}].${DIFFERENT_SESSION_START_TIME}`,
    type: TimeSyncedWithActStartTime
  }
];

export default { associatedFields, summaryRender };
