import get from 'lodash/get';
import { AssociatedField } from '../../requiredDetails/requiredDetail.type';
import Act, { ActCode } from '../../../../shared/domain/activity/act/model/Act';
import surgeryRoom from '../../requiredDetails/SurgeryRoom/surgeryRoom';
import { getFeatureConfig } from '../../../../userCustomization/featureToggle';
import { DISPLAY_SURGERY_ROOM_REQUIRED_DETAIL } from '../../../../userCustomization/featureNames';
import typeOfAnesthesia from '../../requiredDetails/TypeOfAnesthesia/typeOfAnesthesia';
import { SURGERY_ROOM } from '../../../../shared/ramq/requiredDetails/surgeryRoom';
import ActPerformedAloneWithAmountLowerThanMaximumAmountPredicate from './ActPerformedAloneWithAmountLowerThanMaximumAmountPredicate';
import { InterventionSide } from 'shared/ramq/requiredDetails/interventionSide';
import { PIN_REMOVAL_CODES } from './pinRemovalRequiredDetailsAppender';

const rule30RequiredDetailsAppender = (code: ActCode, act: Act): AssociatedField[][] => {
  if (containsBilateralInterventionSide(act.codes)) {
    return [];
  }

  if (!getFeatureConfig(DISPLAY_SURGERY_ROOM_REQUIRED_DETAIL, false)) {
    return [];
  }

  if (code.isSupplement) {
    return [];
  }

  const mainCode = ActPerformedAloneWithAmountLowerThanMaximumAmountPredicate.getMainCodeMatchingPredicate(act);

  if (!mainCode || mainCode.id !== code.id) {
    return [];
  }

  if (PIN_REMOVAL_CODES.includes(code.code)) return [];

  const requiredDetails: AssociatedField[][] = [];

  if (get(code, SURGERY_ROOM, false)) {
    requiredDetails.push(typeOfAnesthesia.associatedFields);
  }

  requiredDetails.push(surgeryRoom.associatedFields);

  return requiredDetails;
};

const containsBilateralInterventionSide = (codes: ActCode[]): boolean =>
  codes.some(({ interventionSide }) => interventionSide === InterventionSide.BILATERAL);

export default rule30RequiredDetailsAppender;
