import get from 'lodash/get';
import { AssociatedField, AssociatedValidation, RequiredDetail } from '../requiredDetail.type';
import { DeletableValueOnUnmountSingleSelectCheckboxGroup } from '../../../components/Form/Fields';
import { AnesthesiaType, TYPE_OF_ANESTHESIA } from '../../../../shared/ramq/requiredDetails/typeOfAnesthesia';

const associatedFields: AssociatedField[] = [
  {
    label: "Type d'anesthésie",
    getName: (index) => `codes[${index}].${TYPE_OF_ANESTHESIA}`,
    type: DeletableValueOnUnmountSingleSelectCheckboxGroup,
    list: [
      { label: 'Générale', value: AnesthesiaType.GENERAL },
      { label: 'Régionale', value: AnesthesiaType.REGIONAL },
      { label: 'Locale', value: AnesthesiaType.LOCAL }
    ],
    props: {
      defaultValue: AnesthesiaType.REGIONAL
    }
  }
];

const associatedValidations: AssociatedValidation[] = [];

const summaryRender = (code) => [
  {
    name: "Type d'anesthésie",
    value: get(code, TYPE_OF_ANESTHESIA, '-')
  }
];

const pinRemovalTypeOfAnesthesia: RequiredDetail = {
  summaryRender,
  associatedFields,
  associatedValidations
};

export default pinRemovalTypeOfAnesthesia;
