import get from 'lodash/get';
import { set } from 'lodash';
import { AssociatedField, AssociatedValidation, RequiredDetail } from '../requiredDetail.type';
import YES_NO_CHECKBOX_VALUES from '../../../ui/shared/form/constants';
import { DeletableValueOnUnmountSingleSelectCheckboxGroup } from '../../../components/Form/Fields';
import { SURGERY_ROOM } from '../../../../shared/ramq/requiredDetails/surgeryRoom';
import { combineErrors } from '../../../validations/act/sendValidation/sendValidation';

const associatedFields: AssociatedField[] = [
  {
    label: "Salle d'opération",
    getName: (index) => `codes[${index}].${SURGERY_ROOM}`,
    type: DeletableValueOnUnmountSingleSelectCheckboxGroup,
    list: YES_NO_CHECKBOX_VALUES,
    props: {
      defaultValue: true
    }
  }
];
export const ERROR = "Vous ne pouvez facturer ce code que dans une salle d'opération";

const generateError = (index) =>
  set({ elementIdToScrollTo: `codes[${index}].${SURGERY_ROOM}` }, `codes[${index}].${SURGERY_ROOM}`, ERROR);

export const cannotBillCodeOutsideSurgeryRoom = (act) => {
  const codesInError = act.codes.map((code, index) => (code[SURGERY_ROOM] === false ? generateError(index) : {}));
  return codesInError.reduce(combineErrors, {});
};

const associatedValidations: AssociatedValidation[] = [cannotBillCodeOutsideSurgeryRoom];

const summaryRender = (code) => [
  {
    name: "Salle d'opération",
    value: get(code, SURGERY_ROOM, 'Oui')
  }
];

const pinRemovalSurgeryRoom: RequiredDetail = {
  summaryRender,
  associatedFields,
  associatedValidations
};

export default pinRemovalSurgeryRoom;
