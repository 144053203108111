import { flatten } from 'lodash';
import { RAMQCodes, TimedAttributeKey } from 'shared/ramq/RamqCodes';
import BIOPSY_NOT_RELATED_TO_ACT from '../../../shared/ramq/requiredDetails/biopsy';
import MEASUREMENT_ELEMENTS from '../../../shared/ramq/requiredDetails/measurementElements';
import PRE_SURGERY_INTERVENTION from '../../../shared/ramq/requiredDetails/preSurgeryIntervention';
import isDefined from '../../../shared/utils/isDefined';
import { REQUIRE_END_TIME_FOR_SURGERY_CODES } from '../../../userCustomization/featureNames';
import { getFeatureConfig } from '../../../userCustomization/featureToggle';
import biopsyNotRelatedToSurgicalProcedure from '../requiredDetails/BiopsyNotRelatedToSurgicalAct/biopsyNotRelatedToSurgicalAct';
import endTimeRequired from '../requiredDetails/EndTimeRequired/endTimeRequired';
import measurementElements from '../requiredDetails/MeasurementElements/measurementElements';
import preSurgeryIntervention from '../requiredDetails/PreSurgeryIntervention/preSurgeryIntervention';
import roleSelect from '../requiredDetails/RoleSelect/roleSelect';
import { endTimeIsRequiredForCode } from './ActCodeEndTimeValidation/actCodeEndTimeValidation';
import { startTimeIsRequiredForCode } from './actCodeStartTimeValidation';
import startTimeAccordingToCode from '../requiredDetails/StartTimeAccordingToCode/startTimeAccordingToCode';
import ActCodeRequiredDetailsFactory from '../requiredDetails/ActCodeRequiredDetails/ActCodeRequiredDetailsFactory';
import exceptionalSurgeryHoursService from '../../../shared/act/services/ExceptionalSurgeryHoursService';
import complexSurgeryIdentifier from '../../../shared/act/domain/service/ComplexSurgeryIdentifier';
import { BILLING_BY_ACT_FOR_SEDATION_ANALGESIA_ONLY_MADE_BY_ANOTHER_DOCTOR_REQUIRED_DETAIL } from '../../../shared/ramq/requiredDetails/rule28';
import billingByActForSedationAnalgesiaOnly from '../requiredDetails/BillingByActForSedationAnalgesiaOnly/billingByActForSedationAnalgesiaOnly';
import { codeIsInRole2 } from '../../../shared/domain/activity/act/service/actCodeUtils';
import { getStore } from '../../reduxStore/configureStore';
import { selectSpecialty } from '../../containers/User/selectors';
import pinRemovalSurgeryRoom from '../requiredDetails/SurgeryRoom/pinRemovalSurgeryRoom';
import { SURGERY_ROOM } from 'shared/ramq/requiredDetails/surgeryRoom';
import { PIN_REMOVAL_CODES } from '../ui/rules/pinRemovalRequiredDetailsAppender';

const getAdditionalValidationsRequiredForCode = (code, act) => {
  const requiredDetailsValidations = ActCodeRequiredDetailsFactory.create(code, act).map(
    (requiredDetail) => requiredDetail.associatedValidations || []
  );

  const validations = requiredDetailsValidations.concat(
    addEndTimeValidationIfCodeIsRole2(code, act),
    addEndTimeValidationIfEndTimeIsRequiredForCode(code),
    addStartTimeValidationForSpecificCode(code),
    addPreSurgeryValidation(code),
    addBiopsyValidation(code),
    addMeasurementElementValidation(code),
    addEndTimeRequiredValidationWhenExceptionalSurgeryIsBilledBySurgeon(act),
    addEndTimeRequiredValidationWhenComplexSurgeryIsBilledBySurgeon(act),
    addRule28Validation(code),
    addEndTimeRequiredValidationWhenPainCodeIsBilled(act),
    addPinRemovalSurgeryRoomValidation(code, act)
  );

  return flatten(validations);
};

const addEndTimeValidationIfCodeIsRole2 = (code, act) => {
  const userSpecialty = selectSpecialty()(getStore().getState());

  if (codeIsInRole2(code, act, userSpecialty)) {
    return roleSelect.associatedValidations;
  }

  return [];
};

const addPreSurgeryValidation = (code) =>
  isDefined(code[PRE_SURGERY_INTERVENTION]) ? preSurgeryIntervention.associatedValidations : [];
const addBiopsyValidation = (code) =>
  isDefined(code[BIOPSY_NOT_RELATED_TO_ACT]) ? biopsyNotRelatedToSurgicalProcedure.associatedValidations : [];
const addRule28Validation = (code) =>
  isDefined(code[BILLING_BY_ACT_FOR_SEDATION_ANALGESIA_ONLY_MADE_BY_ANOTHER_DOCTOR_REQUIRED_DETAIL])
    ? billingByActForSedationAnalgesiaOnly.associatedValidations
    : [];
const addMeasurementElementValidation = (code) =>
  isDefined(code[MEASUREMENT_ELEMENTS]) ? measurementElements.associatedValidations : [];
const addEndTimeValidationIfEndTimeIsRequiredForCode = ({ code }) =>
  endTimeIsRequiredForCode(code) ? endTimeRequired.associatedValidations : [];
const addStartTimeValidationForSpecificCode = ({ code }) =>
  startTimeIsRequiredForCode(code) ? startTimeAccordingToCode.associatedValidations : [];
const addEndTimeRequiredValidationWhenExceptionalSurgeryIsBilledBySurgeon = ({ codes, place, date, start }) =>
  getFeatureConfig(REQUIRE_END_TIME_FOR_SURGERY_CODES, false) &&
  exceptionalSurgeryHoursService.isExceptionalSurgery({ codes, place, date, start })
    ? endTimeRequired.associatedValidations
    : [];
const addEndTimeRequiredValidationWhenComplexSurgeryIsBilledBySurgeon = ({ codes }) =>
  getFeatureConfig(REQUIRE_END_TIME_FOR_SURGERY_CODES, false) && complexSurgeryIdentifier.isComplexSurgery(codes)
    ? endTimeRequired.associatedValidations
    : [];

const addEndTimeRequiredValidationWhenPainCodeIsBilled = ({ codes, date }) => {
  const containsPainCode = codes.some(({ code }) => {
    const ramqCode = RAMQCodes.get(code);

    if (!ramqCode) return false;

    return (
      RAMQCodes.getTimedAttribute(ramqCode, TimedAttributeKey.isPainDay, date) ||
      RAMQCodes.getTimedAttribute(ramqCode, TimedAttributeKey.isPainDayExclusive, date)
    );
  });

  return containsPainCode ? endTimeRequired.associatedValidations : [];
};

const addPinRemovalSurgeryRoomValidation = (code) => {
  if (isDefined(code[SURGERY_ROOM]) && PIN_REMOVAL_CODES.includes(code.code)) {
    return pinRemovalSurgeryRoom.associatedValidations;
  }

  return [];
};

export default getAdditionalValidationsRequiredForCode;
