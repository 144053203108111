import {
  ActivityAdminStatus,
  ActivityAttachment,
  ActivityBillingType,
  ActivityLastActiveBill,
  ActivityNote,
  ActivityPlace,
  ActivityRamqExchange,
  ActivityStatus,
  ActivityType,
} from './activity';
import { AuditInformation } from './global';
import { UserSpecialty, UserSpecialtyId } from './user';
import {
  RamqSpecialistActCodeDTO,
  RamqSpecialistActCodeMeasurementElement,
  RamqSpecialistActCodeNatureType,
} from './ramq';

export interface Act {
  date: number;
  codes: ActCode[];
  start?: number | null;
  dateHospitalisation?: number;
  type: ActivityType.ACT;
  userId: string;
  ramqPatient?: ActRamqPatient | null;
  patientInformation?: ActPatientInformation;
  billingType?: ActivityBillingType;
  id: string;
  nam?: string;
  place: ActivityPlace | null;
  status: ActivityStatus;
  synchronized?: boolean;
  contextElements?: ActContextElement[];
  forceOptimization?: boolean;
  adminStatus: ActivityAdminStatus;
  validations?: never[];
  auditInformation?: AuditInformation;
  createdOn?: number;
  optimizations?: {
    applied: string[];
    notApplied: string[];
  };
  note?: string;
  guard?: boolean;
  poolNumber?: null | string;
  activityArea?: null | ActActivityArea;
  patientContext?: {
    admissionDate?: number;
    diagnostic?: Array<ActPatientDiagnostic | null> | ActPatientDiagnostic;
  };
  duplicationProcessDone?: boolean;
  end?: number | null;
  computedBillRules?: {
    applied: string[];
    notApplied: string[];
  };
  xml?: string;
  ramqExchange?: ActivityRamqExchange[];
  computedBill?: {
    date?: number;
    billLines?: ActBillLine[];
    intervenantNumber: string;
    start?: number;
    externalBillNumber?: string;
    doctor: {
      paymentMode?: number;
      practiceNumber: string;
      identifierType: number;
      groupNumber?: string;
      specificBillingGroups?: Array<{
        number: string;
        doctors?: Array<{
          lastName: string;
          firstName: string;
          specialty?: UserSpecialty;
          practiceNumber: string;
          isManager: number;
          id: string;
        }>;
        name: string;
        id?: string;
        codes?: Array<{
          code: string;
          measurementElements: never[];
          units: Array<{
            role: string;
            units: string;
          }>;
          natureType: RamqSpecialistActCodeNatureType;
        }>;
        types?: Array<'mixte' | 'lumpSum'>;
        groupBillingTypes?: ActivityBillingType[];
      }>;
    };
    persons?: Array<{
      nam?: string;
      type: 'patientWithId' | 'patientWithoutId' | 'personWithId';
      typeIdOtherPerson?: number;
      id?: string;
      typeOtherPerson?: number;
      patientInformation?: {
        firstName?: string;
        lastName: string;
        gender?: string;
        respondent?: {
          nam?: string;
          firstName?: string;
          lastName?: string;
          gender?: string;
          birthDate?: number;
        };
        type: string;
        birthDate?: number;
        zipCode?: string;
        address?: string;
        province?: string;
        city?: string;
        birthOrder?: number | string;
        outsideRamq?: {
          billedDisclaimerAccepted?: boolean;
          billingManager?: 'medyx' | 'doctor' | null;
          allowMedyxToContactWithPatient?: boolean | null;
          patientType?: ActOutsideRamqPatientType;
        };
        urgent?: {
          disclaimerAccepted: boolean;
        };
        iuc?: string;
        nam?: string;
      };
      carence?: string;
    }>;
    billingType?: ActivityBillingType;
    end?: number | null;
    externalReferenceNumber?: string;
    location?: {
      physicalLocation?: {
        activityArea?: string;
        id: string;
        type: number;
      };
      geographicalLocation?: {
        id: string;
      };
    };
    globalContextElements?: number[];
    mainPlaceRef?: string;
    lastActiveBill?: ActivityLastActiveBill | null;
    patientDiagnostic?: ActPatientDiagnostic;
    authorizationNumber?: string;
    patientEvent?: {
      eventDate: number;
      eventType?: {
        description: string;
        id: ActPatientEventType;
      };
    };
    remp?: number | null;
    urgentCallDetails?: {
      guard: boolean;
      date?: number;
      time?: number;
    };
  };
  billNumberMap?: { [key: string]: boolean };
  doctor?: ActReferenceDoctor | null;
  assets?: ActivityAttachment[] | null;
  extractedNams?: ActExtractedNam[];
  lastActiveBill?: {
    totalAmount?: string;
    billNumber: string;
    communicationToken: string;
    lastBillLineNumber: number;
  } | null;
  manualCorrection?: null | string;
  adminNotes?: ActivityNote[];
  patientDiagnostic?: ActPatientDiagnostic | null;
  attachments?: ActivityAttachment[];
  corrections?: ActivityNote[];
  patientEvent?: {
    eventDate: number;
    eventType?: {
      description: string;
      id: ActPatientEventType;
    };
  };
  remp?: number | null;
  skipSupportPlanOptimization?: boolean;
  manualXml?: null;
  blocked?: boolean;
  linkedActivities?: string[];
  freeTextPatientDiagnostic?: string;
  paidAt?: number | null;
  urgentCallDetails?: {
    guard: boolean;
    date?: number;
    time?: number;
  } | null;
  lastActiveBillToBeRetried?: {
    totalAmount?: string;
    billNumber: string;
    communicationToken: string;
    lastBillLineNumber: number;
  };
  ramqContextElements?: ActRamqContextElement[];
  paidAmount?: null | string;
}

export enum ActActivityArea {
  EXTERNAL_CLINIC = '1',
  GERIATRIC_SECTION = '2',
  GENERAL_CARE = '3',
  EXTENDED_CARE_PROLONGES = '4',
  EXTENDED_CARE_HEBERGEMENT = '5',
  PSYCHIATRIC_SECTION = '8',
  CORONARY_UNIT = '15',
  INTENSIVE_CARE_UNIT = '16',
  EMERGENCY_CLINIC = '35',
  BURN_VICTIMS_CENTER = '43',
  HEAVY_READAPTATION_UNIT = '49',
  FAMILY_MEDICINE_UNIT = '52',
  CHRONIC_PAIN_CENTER = '53',
  NEONATAL_UNIT = '54',
  HEPATOLOGY_UNIT = '55',
}

export enum ActPatientInformationType {
  NAM = 'NAM',
  BABY = '2',
  URGENCY = '3',
  STATEMENT = '9',
  OUTSIDE_RAMQ = 'outsideRamq',
}

export enum GenderNumber {
  MALE = '1',
  FEMALE = '2',
}

export enum ActOutsideRamqPatientType {
  REFUGEE = 'refugee',
  OUTSIDE_PROVINCE = 'outsideRamq',
  PRIVATE_INSURANCE = 'privateInsurance',
  MILITARY = 'military',
  PRISONER = 'prisoner',
  OTHER = 'other',
  ABROAD_STUDENT = 'abroadStudent',
  BILLED = 'billed',
}

export interface ActCode extends RamqSpecialistActCodeDTO {
  id: string;
  measurementElements: ActCodeMeasurementElement[];
  isPlaceOnly?: boolean;
  diagnostic?: ActPatientDiagnostic[] | ActPatientDiagnostic | null;
  dateHospitalisation?: number | null;
  professionalReference?: ActCodeProfessionalReference;
  amPm?: Array<'am' | 'pm'> | null;
  universityNumber?: ActCodeUniversityNumber | null;
  noStudents?: string[]; //This key is related to "residents". Since Medyx legacy used "noStudents" key, it was kept for compatibility. All student codes need to be in this array, even if they are duplicated in the "residents" array.
  residents?: ActCodeResident[]; //This key is related to "noStudents". Since Medyx legacy only used "noStudents" key, it was kept for compatibility and is used only if the residents is found using the search feature to provide his name.
  role?: number | null;
  exceptionalCircumstances?: boolean | null;
  exitDate?: number | null;
  endTimeWhenDurationIsOver60Minutes?: number | null;
  endTimeWhenDurationIsOver30Minutes?: number | null;
  billingForSedationAnalgesiaOnly?: 'pg28PdtOrSurgeryMadeByAnotherDoctor' | 'pg28PdtOrSurgeryNotBillable' | null;
  billingByActForSedationAnalgesiaOnly?: boolean;
  pcma?: boolean;
  originLocationOfRemoteConsultation?: {
    place?: {
      number: string;
      name: string;
    } | null;
    type: 'geographical' | 'physical' | null;
    postalCode?: null | string;
  };
  apgar?: string;
  gender?: GenderNumber | null;
  birthTime?: number | null;
  authorizationNumber?: string | null;
  placeReference?: ActivityPlace | null;
  role1ForcedStartTime?: number | null;
  followupType?: ActCodeFollowupType | null;
  consultationCarriedOutElsewhereThanCRDS?: boolean;
  alcoholPhenolInjection?: boolean | null;
  firstFollowup?: boolean | null;
  surgeryContextElement?: null | string;
  imc?: string;
  discontinuedService?: boolean;
  interventionSide?: ActCodeInterventionSide | null;
  differentSite?: 'left' | 'right';
  additionalStartTimes?: Array<number | null>;
  ramqContextElements?: ActRamqContextElement[];
  unknownProfessionalReference?: {
    firstName?: string | null;
    lastName?: string | null;
    professionalType?: ActCodeProfessionalReferenceType;
    territory?: number | null;
  };
  cfc?: boolean;
  rolesMap?: { [key: string]: boolean };
  breastReconstruction?: boolean;
  patientNoShow?: boolean;
  differentChronicPainProblem?: boolean;
  contralateralBreast?: boolean;
  biopsyNotRelatedToAct?: boolean | null;
  surgeryRoom?: boolean;
  typeOfAnesthesia?: 'general' | 'regional';
  telemedicineOrRemoteType?: 'phone' | 'visio';
  refundAmount?: string;
  specialSituation?: ActCodeSpecialSituationCodes | null;
  specialSituationSpecificCode?: string;
  adrenalContralateral?: boolean;
  place?: ActivityPlace | null;
  differentSessionStartTime?: number | null;
}

export enum ActCodeInterventionSide {
  BILATERAL = 'bilateral',
  LEFT = 'left',
  RIGHT = 'right',
}

export interface ActBillLine extends ActCode {
  contextElements: Array<number | string>;
  date?: number;
  endDateTime?: number;
  startDateTime?: number;
  role: number;
}

export interface ActRamqPatient {
  isAdmissible?: boolean | null;
  firstName: null | string;
  lastName: null | string;
  fullName: null | string;
  dateOfBirth: string | null;
  nam?: string;
  age?: number | null;
}

export interface ActPatientInformation {
  type: ActPatientInformationType;
  firstName?: string;
  lastName?: string;
  gender?: GenderNumber | null;
  respondent?: {
    nam?: string;
    firstName?: string;
    lastName?: string;
    gender?: GenderNumber;
    birthDate?: number;
  };
  birthDate?: number | string;
  outsideRamq?: {
    billingManager?: 'medyx' | 'doctor' | null;
    allowMedyxToContactWithPatient?: boolean | null;
    patientType?: ActOutsideRamqPatientType;
  };
  province?: string;
  nam?: string;
  insurance?: {
    phoneNumber?: string;
    policyCertificateNumber: string;
    name: string;
    fileNumber?: string;
    address?: string;
    contactPerson?: string;
    faxNumber?: string;
    email?: string;
  };
  phoneNumber?: string;
  address?: string;
  email?: string;
  zipCode?: string;
  city?: string;
  shiftNamCentury?: boolean;
  iuc?: string;
  birthOrder?: number | string;
  identificationNumber?: string;
  detentionCenterAddress?: string;
  urgent?: {
    disclaimerAccepted: boolean;
  };
  paymentType?: 'cash' | 'credit' | 'hospital';
  groupNumberOrUniversityName?: string;
  studentNumber?: string;
  communicationMedyxPatientAuthorisation?: boolean | null;
  expiredNam?: string;
  carence?: string;
  phone?: string;
}

export interface ActRoleMetadata {
  effectiveEndDate: string | number | null;
  specialty: null | UserSpecialtyId;
  role: string;
  specialtyName: UserSpecialty | null;
  effectiveStartDate: string | number;
  roleName: string;
  units: number | null;
}

export interface ActCodeMeasurementElement extends RamqSpecialistActCodeMeasurementElement {
  value?: string;
}

export interface ActCodeTimedAttribute {
  effectiveEndDate: string | number | null;
  effectiveStartDate: string | number | null;
  value: unknown;
  key: string;
}

export interface ActPatientDiagnostic {
  effectiveEndDate?: string | number | null;
  code: string;
  effectiveStartDate?: string | number | null;
  description: string;
  medicalClassificationSystemId?: string;
  synchronized?: boolean;
  id?: string;
  auditInformation?: AuditInformation;
  shortDescription?: string;
  effectStartDate?: string;
  objectID?: string;
  searchString?: string;
}

export interface ActCodeProfessionalReference {
  doctor?: ActReferenceDoctor | null;
  professionalType: null | ActCodeProfessionalReferenceType;
  firstName?: null | string;
  lastName?: null | string;
  practiceNumber?: null | string;
}

export interface ActReferenceDoctor {
  firstName: string;
  lastName: string;
  practiceNumber?: number | string;
  facility?: null | string;
}

export enum ActCodeFollowupType {
  CANCER = 'CANCER',
  SKIN_CANCER = 'SKIN_CANCER',
  TUMOR = 'TUMOR',
  TRANSPLANTATION = 'TRANSPLANTATION',
}

export interface ActRamqContextElement {
  effectiveEndDate?: null | string | number;
  synchronized?: boolean;
  code: string;
  effectiveStartDate: string | number;
  description: string;
  id: string;
  auditInformation?: AuditInformation;
  objectID?: string;
  originalDescription?: string;
}

export interface ActExtractedNam {
  isValid: boolean;
  confidence: number;
  vertexes: Array<{
    x: number;
    y: number;
  }>;
  nam: string;
}

export enum ActContextElement {
  ANESTHETIC_COMPLEXITY_GREATER_THAN_4_HOURS = 'anestheticComplexityGreaterThan4Hours',
  ANESTHETIC_COMPLEXITY_GREATER_THAN_8_HOURS = 'anestheticComplexityGreaterThan8Hours',
  COEUR_POUMON = 'coeurPoumon',
  GREATER_THAN_4_HOURS = 'greaterThan4Hours',
  INTENSIVE_CARE_ON_GUARD_DAY = 'intensiveCareOnGuardDay',
  INTENSIVE_CARE_UNIT_SUPPORT_DAY = 'intensiveCareUnitSupportDay',
  INTERRUPT = 'interrupt',
  NON_URGENT = 'nonUrgent',
  ROLE3 = 'role3',
  ROLE4 = 'role4',
  SDIFF = 'sdiff',
  URGENT = 'urgent',

  // Context elements controlled by required details from codes
  ACTIVITES_ANNULEES = 'activitesAnnulees',
  AUCUN_PER_DIEM = 'aucunPerDiem',
  DEMI_PER_DIEM = 'demiPerDiem',
  DEMI_PER_DIEM_DURING_DAY = 'demiPerDiemDuringDay',
  ENCEINTE_COVID = 'enceinteCovid',
  GREATER_THAN4_HOURS = 'greaterThan4Hours',
  ISOLEMENT_COVID = 'isolementCovid',
  MD_ENCEINTE = 'mdEnceinte',
  MD_INFECTE = 'mdInfecte',
  MD_QUARANTAINE = 'mdQuarantaine',
  MD_REAFFECTE = 'mdReaffecte',
  MD_REAFFECTE_AILLEURS_COVID = 'mdReaffecteAilleursCovid',
  MD_REAFFECTE_AUTRE_LIEU = 'mdReaffecteAutreLieu',
  MD_REAFFECTE_COVID_ETABLISS = 'mdReaffecteCovidEtabliss',
  PER_DIEM = 'perDiem',
  SERVICE_PATIENT_COVID = 'servicePatientCovid',
  SNT_AMBULATOIRE_CLINIQUE_DESIGNE_EVALUATION = 'sntAmbulatoireCliniqueDesigneEvaluation',
  SNT_AMBULATOIRE_COVID = 'sntAmbulatoireCovid',
  SNT_NON_AMBULATOIRE = 'sntNonAmbulatoire',
  SNT_NON_AMBULATOIRE_COVID = 'sntNonAmbulatoireCovid',
  TELEMEDECINE_AILLEURS_COVID = 'telemedecineAilleursCovid',
  TELEMEDECINE_CABINET = 'telemedecineCabinet',
  TELEMEDECINE_HOSPITALIERE = 'telemedecineHospitaliere',
  TELEMEDECINE_PAR_TELEPHONE = 'telemedecineParTelephone',
  TELEMEDECINE_PAR_VISIOCONSULTATION = 'telemedecineParVisioconsultation',
  WEBINAIRE_DIFFERE = 'webinaireDiffere',
  WEBINAIRE_DIRECT = 'webinaireDirect',
}

export enum ActCodeProfessionalReferenceType {
  DOCTOR = '1',
  OPTOMETRIST = '2',
  SPECIALIST_NURSE_PRACTITIONER = '3',
  SOCIAL_WORKER = '4',
  OCCUPATIONAL_THERAPIST = '5',
  MIDWIFE = '6',
  PHYSIOTHERAPIST = '7',
  PSYCHOLOGIST = '8',
  AUDIOLOGIST = '9',
  AUDIOPROSTHETIST = '10',
  CORONER = '11',
  COMMISSION_DOCTOR = '12',
  SPEECH_THERAPIST = '13',
  PHARMACIST = '14',
  GENERAL_PRACTITIONER = '15',
  SPECIALIST_DOCTOR = '16',
  RESIDENT = '17',
  DENTIST = '18',
  OTHER = '99',
}

export enum ActCodeSpecialSituationCodes {
  NONE = 'none',
  SLEEP_TEST_GREATER_THAN_8H = '05012',
  SLEEP_TEST_4H_TO_8H = '05014',
  SLEEP_TEST_LESS_THAN_4H = '05020',
  ORAL_TREATMENT = '05040',
  TUMORS_CLINIC = '05047',
  IMMUNOGLOBULIN_MONITORING = '05086',
  OTHER = 'other',
}

export interface ActCodeResident {
  practiceNumber: string;
  firstName: string;
  lastName: string;
}

export enum ActCodeUniversityNumber {
  SHERBROOKE = '50043',
  LAVAL = '50013',
  MONTREAL = '50023',
  MCGILL = '50033',
}

export enum ActPatientEventType {
  CNESST = '1',
  PERIOD = '2',
  LABOR = '3',
}
