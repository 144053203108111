import moment from 'moment-timezone';
import upperFirst from 'lodash/upperFirst';
import { CAPQ_CODES } from 'shared/periods/lumpSum/timeSlot/timeSlotCalculators/CAPQTimeSlotCalculator';
import TimeSlotCalculatorFactory from 'shared/periods/lumpSum/timeSlot/timeSlotCalculators/TimeSlotCalculatorFactory';
import LUMPSUM_DISPENSARY_AREA_MAP from 'shared/ramq/domainValues/lumpsumDispensaryAreas';

const WEEK_END_AND_HOLIDAY_EXCEPTION = 'Autorisé à fournir des soins les week-ends et les jours fériés';

export default (documentToSave) => {
  const { complementaryInformation, days = [] } = documentToSave;

  if (days.length === 0)
    return { ...documentToSave, complementaryInformation: complementaryInformation?.replace(/\*.+\*/, '') };

  let newComplementaryInformation: string = '';

  if (days.some(({ code }) => code === '002064')) {
    newComplementaryInformation = WEEK_END_AND_HOLIDAY_EXCEPTION;
  }

  let lineCursor = -2;
  let columnCursor = 0;
  let previousTimeSlot = null;
  let previousDate = null;

  for (const day of days) {
    if (!day.code || !day.start || !day.end) continue;

    const currentDayFormattedDate = upperFirst(moment(day.start).format('dddd D MMMM'));

    const timeSlots = TimeSlotCalculatorFactory.create(day.code).calculate(day);

    const timeSlotsDescription = timeSlots.reduce((accumulator, { details, timePeriod }) => {
      if (previousDate === timePeriod.date && previousTimeSlot === timePeriod.timeslot && columnCursor < 2) {
        columnCursor++;
      } else {
        columnCursor = 0;
        lineCursor += 3;
      }

      const isRemoteConsultation = details.some(({ sector }) => sector === LUMPSUM_DISPENSARY_AREA_MAP.remote);
      const isCAPQ = details.some(({ code }) => CAPQ_CODES.includes(code));

      if (isRemoteConsultation || isCAPQ) {
        let newTimeSlotInformation = `REFRE ${lineCursor + columnCursor} : `;

        if (isCAPQ) {
          newTimeSlotInformation += `${moment(timePeriod.start).format('HH:mm')} `;
        }

        if (isRemoteConsultation) {
          const originLocationOfRemoteConsultation =
            day.originLocationOfRemoteConsultation.type === 'physical'
              ? day.originLocationOfRemoteConsultation.place.number
              : day.originLocationOfRemoteConsultation.postalCode;

          newTimeSlotInformation += `Lieu ${originLocationOfRemoteConsultation} `;
        }

        accumulator.push(newTimeSlotInformation);
      }
      previousTimeSlot = timePeriod.timeslot;

      return accumulator;
    }, []);

    if (timeSlotsDescription.length === 0) continue;

    if (previousDate !== day.date) {
      newComplementaryInformation += `${currentDayFormattedDate} : ${timeSlotsDescription.join(', ')}`;
    } else {
      newComplementaryInformation += ` ${timeSlotsDescription.join(', ')}`;
    }

    previousDate = day.date;
  }

  const currentComplementaryInformation = complementaryInformation?.replace(/\*.+\*/, '').trim() ?? '';

  if (newComplementaryInformation.length > 0) {
    return {
      ...documentToSave,
      complementaryInformation: `${currentComplementaryInformation} *${newComplementaryInformation.trim()}*`
    };
  } else {
    return { ...documentToSave, complementaryInformation: currentComplementaryInformation };
  }
};
