import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import FormElement from '../../../../../components/Form/FormElement/FormElement';
import { PhoneNumberField, SingleLineField } from '../../../../../components/Form/Fields';
import SingleSelectCheckboxGroup from '../../../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import CashAmountField from '../../../../../components/Form/custom/CashAmountField';
import { actFormAdminFields } from '../../../constants';

export const PAYMENT_TYPE_VALUES = [
  { label: 'Via hôpital', value: 'hospital' },
  { label: 'Carte de crédit', value: 'credit' },
  { label: 'Comptant', value: 'cash' }
];

export interface Props {}

export const BilledForm: React.FunctionComponent<Props> = () => (
  <>
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h6">
            Identification du patient
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement name="patientInformation.lastName" required label="Nom du patient" component={SingleLineField} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name="patientInformation.firstName"
            required
            label="Prénom du patient"
            component={SingleLineField}
          />
        </Grid>

        <Grid item xs={12}>
          <FormElement name="patientInformation.address" required label="Adresse" component={SingleLineField} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement name="patientInformation.phone" required label="Téléphone" component={PhoneNumberField} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement name="patientInformation.email" label="Adresse courriel" component={SingleLineField} />
        </Grid>

        <Grid item xs={12}>
          <Typography gutterBottom variant="h6">
            Paiement
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <FormElement
            name="patientInformation.paymentType"
            required
            label="Type de paiement"
            list={PAYMENT_TYPE_VALUES}
            component={SingleSelectCheckboxGroup}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormElement
            name={actFormAdminFields.manualCorrection}
            required
            label="Montant"
            component={CashAmountField}
          />
        </Grid>
      </Grid>
    </CardContent>
  </>
);

export default BilledForm;
