import { WithStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect, useSelector } from 'react-redux';
import useTranslate from 'application/hooks/use-translate';
import SingleSelectCheckboxGroup from '../../../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import { SelectorField } from '../../../../../components/Form/Fields';
import FormElement from '../../../../../components/Form/FormElement/FormElement';
import YES_NO_CHECKBOX_VALUES from '../../../../../ui/shared/form/constants';
import { actFormFields } from '../../../constants';
import OutsideRamqPatientTypeSwitch from '../OutsideRamqPatientTypeSwitch/OutsideRamqPatientTypeSwitch';
import { BILLING_MANAGER_CHECKBOX_VALUES, PATIENT_TYPES_LIST } from './constants';
import { selectOutsideRamqPatientType, selectPatientInformation } from '../../../selectors';
import AlertSeverity from 'app/ui/shared/severity/AlertSeverity';
import { selectCurrentLoggedUser } from 'app/containers/Authentication/selectors';
import Alert from 'app/components/Alert/Alert';
import Role from 'shared/domain/authentication/Role';
import { isUserAtLeast } from 'application/utilities/authentication';
import User from 'shared/domain/user/User';
import { ActPatientInformation } from 'shared/medyx-core/types/act';

export const styles = ({ spacing }) =>
  ({
    alertBoxWrapper: {
      marginTop: spacing(2)
    },
    alertBoxChildrenWrapper: {
      paddingTop: spacing(2)
    },
    form: {
      paddingBottom: spacing(2)
    },
    field: {
      marginTop: spacing(2)
    }
  }) as const;

export interface Props extends WithStyles<typeof styles> {
  loggedUser: User;
  patientInformation: ActPatientInformation;
}

export function OutsideRamqForm({ loggedUser, patientInformation, classes }: Props) {
  const translate = useTranslate();
  const { outsideRamq } = patientInformation;

  return (
    <div className={classes.form}>
      <FormElement
        data-testid="patient-type-field"
        name={`${actFormFields.patientInformation}.outsideRamq.patientType`}
        label={translate('no-nam.outside-ramq.patient-type')}
        component={SelectorField}
        list={PATIENT_TYPES_LIST}
      />

      <FormElement
        className={classes.field}
        name={`${actFormFields.patientInformation}.outsideRamq.billingManager`}
        label={translate('no-nam.outside-ramq.authorize-billing-by-medyx')}
        required
        list={BILLING_MANAGER_CHECKBOX_VALUES}
        component={SingleSelectCheckboxGroup}
      />

      <FormElement
        name={`${actFormFields.patientInformation}.outsideRamq.allowMedyxToContactWithPatient`}
        required
        label={translate('no-nam.outside-ramq.authorize-medyx-patient-communication')}
        list={YES_NO_CHECKBOX_VALUES}
        component={SingleSelectCheckboxGroup}
      />

      {!!outsideRamq &&
        outsideRamq.billingManager === 'doctor' &&
        outsideRamq.allowMedyxToContactWithPatient === false && (
          <div className={classes.alertBoxWrapper}>
            <Alert
              message="Cette demande sera transférée automatiquement au statut payé 6 mois après date de service au montant indiqué sur la demande."
              severity={AlertSeverity.WARNING}
              showIcon
              title="Attention"
            >
              <div className={classes.alertBoxChildrenWrapper}>
                <FormElement
                  name="patientInformation.outsideRamq.billedDisclaimerAccepted"
                  required
                  disabled={isUserAtLeast(loggedUser.role, Role.Agent)}
                  label="Je comprends et j'accepte"
                  list={YES_NO_CHECKBOX_VALUES}
                  component={SingleSelectCheckboxGroup}
                />
              </div>
            </Alert>
          </div>
        )}

      <OutsideRamqPatientTypeSwitch />
    </div>
  );
}

export const mapStateToProps = createStructuredSelector({
  outsideRamqPatientType: selectOutsideRamqPatientType(),
  patientInformation: selectPatientInformation(),
  loggedUser: selectCurrentLoggedUser()
});

// Since Typescript 5.4 the connect function raise the following error:
// TS2589: Type instantiation is excessively deep and possibly infinite.
// @ts-ignore
export default compose(withStyles(styles), connect(mapStateToProps))(OutsideRamqForm) as React.FunctionComponent;
